<style>
  .optionchain-module .v-text-field > .v-input__control > .v-input__slot:before,
  .optionchain-module .v-text-field > .v-input__control > .v-input__slot:after,
  .optionchain-module .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after{transform: scaleX(0);}
  .optionchain-module .v-data-footer__select{display:none}
  .optionchain-module .v-slider--vertical{min-height: 60px;}
  .optionchain-table .v-data-footer{justify-content: end;}
  .optionchain-module span.v-slider__tick:after {content: '';display: block;position: absolute;background: #2ea6f5;height: 1px;width: 15px;left: -10px}
  .optionchain-module .v-slider__tick--filled,.optionchain-module  .v-slider__tick{background:none;}
  .optionchain-module .v-slider__track-background,.optionchain-module .v-slider__track-background.primary.lighten-3{background: #2ea6f5!important;}
  .optionchain-module .v-slider--vertical .v-slider__thumb-container,
  .optionchain-module .v-slider--vertical .v-slider__track-container{left: 20%;}
  .optionchain-module .v-slider--vertical .v-slider__ticks-container{left: 35%;}
  .optionchain-table .v-data-footer{display:none}
  .optionchain-table{border-collapse: collapse;background-color: #FFFFFF;width: 100%;border-spacing: 0;}
  .optionchain-table tbody tr{border-bottom: thin solid rgb(225, 225, 225);}
  .optionchain-table  > thead > tr > th{    height: 32px;box-shadow: inset 0 -1px 0 rgb(225 225 225);border-bottom: 0px !important;position: sticky;top: 0;z-index: 2;font-size: 0.75rem;padding: 0 16px;}
  .optionchain-table  > thead > tr:nth-child(2) th{background: inherit!important;padding: 10px 50px 10px 10px;}
  .optionchain-module .theme--light.v-input input{color: rgb(132 132 132);}
.table-wrapper{overflow: auto;}

.bg-lightgreen {background: #eefffc!important;}
.bg-lightred {background: #FFECEC!important;}
.bg-lightyellow {background: #fffbdb; color: #848484;}
.bg-lightgrey {background: hsl(0, 0%, 96%)!important;}
.c-blue{color:#0984E3 }
.bg-lightgrey-1 {background: #efefef;}
[data-font-size] td{padding: 10px 35px 10px 20px!important;}
.optionchain-table.v-data-table > .v-data-table__wrapper > table{border-collapse: collapse;}

.theme--dark .bg-lightgreen {background: #1C3126!important;}
.theme--dark .bg-lightred {background: #371A1A!important;}
.theme--dark .bg-lightyellow {background: #162733; color: #C4C4C4;}
.theme--dark .c-blue{color:#2ea6f5 }
.optionchain-table-container .str-clm{width:130px;}
.theme--dark .bg-lightgrey,.theme--dark .str-clm{background: #2a2e32!important;}

[data-hover] td{background: #e9f6ff!important;}
.theme--dark [data-hover] td{background: #325770!important;}
.v-data-table td{transition:all 0.2s!important;}
</style>
<style scoped>
.v-data-table.v-data-table--fixed-header thead tr:nth-child(2) th{background: inherit!important;padding:10px 50px 10px 10px}
  .flex-table-container {flex-basis: 100%}
  .optionchain-table-container{position:relative;top:0;left:0;width:100%}
  .hidden-layer{position:fixed;left:0;top:0;width:100%;height:100%;
    background: transparent;
    z-index: 4;}
  
  .s2ndline{font-size:12px;}
.optionchain-table-container .buy-sell-area{position: absolute;background: rgb(255 255 255 / 66%);transition:all 0.2s;}
.optionchain-table-container .buy-sell-area .btn{margin: 0px 5px;}
.buy-sell-area{height: 40px;width: 66px;left: 0;top: 0;z-index: 6;padding-top: 7px;}
.buy-sell-area .btn{padding: 2px 7px;border-radius: 3px;width: 20px;height: 20px;letter-spacing: 0px;color: #333;cursor:pointer;opacity:0.9;background: #ccc;}
.buy-sell-area .btn:hover{opacity:1}
.buy-sell-area .btn.buy{background: #00B894;color:#fff}
.buy-sell-area .btn.sell{background: #f44336;color:#fff}
.theme--dark .optionchain-table-container .buy-sell-area{background: rgb(46 46 46 / 66%);}
.margin-label{font-size:12px!important;}
.margin-value{font-size:16px;}
.v-application.theme--light, .v-application.theme--light .v-sheet, .v-application.theme--light .v-card{color:#333}

.negativevalue {color: #00b894 !important; line-height: 22px!important;}
.positivevalue {color: #f44336 !important; line-height: 22px!important;}
/* {border-color: #aeaeae;} */
.font-size.small .f--small,.font-size.medium .f--medium,.font-size.large .f--large{background:#0984E3; color: #fff;}
[data-font-size="small"] td{font-size: 0.6rem!important;}
[data-font-size="medium"] td{font-size: 0.8rem!important;}
[data-font-size="large"] td{font-size: 1rem!important;}
.hide-show-col-list .v-label{font-size: 14px;}
.overflowscroll{overflow-y: scroll;}
.pointer{cursor: pointer;}
.progressindiator{height: 14px!important; margin-top: 2px !important;} 
.verticalline{background: #c8c8c8;margin: 0 20px;height: 15px;width: 1px;}
.v-application .caption{color: #848484;}
.v-application .subtitle-2{color: #848484;}
.v-data-table{position:relative;z-index: 5;}
.v-data-table .str-clm{padding: 0 35px!important;}
.v-application.theme--light .v-data-table > .v-data-table__wrapper > table > thead > tr > th{color:#414141;*font: normal normal 600 14px/10px Segoe UI;}
.v-application.theme--light .v-data-table{color:#848484;*font: normal normal normal 14px/31px Segoe UI;}
.v-progress-linear {background: transparent;overflow: hidden;position: relative;transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);width: 55%;}
.v-application.theme--light .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {background: transparent;}
.search-area {max-width:180px}
.v-slide-group__content {justify-content: space-between !important;}
/* .optionchain-table tr.c-active:hover td,.optionchain-table tr.c-active td{background: #f5f5f5!important;}
.theme--dark .optionchain-table tr.c-active td,.theme--dark .optionchain-table tr.c-active:hover td {background: #39274D!important;} */

.optionchain-table .p-relative{position:relative}
.optionchain-table tr.c-active{position: relative;border-top:2px dashed #00B894;}
.optionchain-table tr.c-active .strike-highlight{position: absolute;left: 0px;background: #00B894;padding:5px;border-radius: 5px;color: #fff;top: -28%;width:100%;font-size:11px}
.strike-price .strike-highlight{
    background: #00B894;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    width: 130px;
    font-size: 11px;
    margin: 0 auto;
    display: inherit;
    position: relative;
    top: -12px;}
/* .optionchain-table tr.c-active .strike-highlight:after{content: "test";position: absolute;left: 50%;background: #00B894;padding: 2px 10px;border-radius: 5px;color: #fff;top: -10px;} */
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: transparent;
}
.hide-show-col-list .v-label {font-size: 13px;}
/* .theme--dark .calls-clm-sub-hd{background: #14211A;}
.theme--dark .puts-clm-sub-hd{background: #271F1F;} */

</style>

<template>

<div class="optionchain-module">
 <v-flex >
    <div class="outer-layer-full"  v-if="closeOuterLayer"></div>
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-col class="pa-2">
          <v-card class="a-box">
            <div class="pa-2 d-flex justify-space-around">
            <v-row class="ma-0 align-center" width="fit-content"  v-for="(item, index) in optionchain" :key="index">    
            <v-autocomplete v-model="instrument" :items="symbolsList"  @change="loadData"
            class="search-area subtitle-2 pt-0 mt-0" hide-no-data hide-details 
            prepend-icon="mdi-magnify" label="" placeholder="Search...ex:Bank Nifty"></v-autocomplete>
              <span  class="caption" :class=" item.percentage >= 0 ? 'negativevalue' : 'positivevalue'">{{ item.percentage }}%</span>
               <span class="light-th mt-1 ml-6"><img class="imgheight" src="@/assets/images/icons/Graph.svg" alt="" /></span>
                <span class="light-th mt-1 ml-4"><img class="imgheight" src="@/assets/images/icons/Info.svg"  alt="" /></span>
                <span class="dark-th mt-1 ml-6"><img class="imgheight" src="@/assets/images/icons/graph-d.svg" alt="" /></span>
                <span class="dark-th mt-1 ml-4"><img class="imgheight" src="@/assets/images/icons/info-d.svg"  alt="" /></span>
                <span class="verticalline"></span>
                <span class="caption">Expiry</span>
                    <v-autocomplete v-model="expirydate" :items="expiryList"  @change="loadData"
                      class="search-area subtitle-2 pt-0 pl-4 mt-0" hide-no-data hide-details 
                      placeholder="Expiry Date"></v-autocomplete>
                <span class="verticalline"></span>
                <span class="caption">ATM IV<span class="ml-2" :class=" item.ivpercentage >= 0 ? 'negativevalue' : 'positivevalue'">{{item.ivpercentage}}%</span></span>
                <span class="verticalline"></span>
                 <span class="caption">IV Chart</span>
                <span class="verticalline"></span>
                <span class="caption">Analyze OI</span>
            </v-row>
             </div>
          </v-card>
        </v-col>
        <v-col xs12="auto" sm12="auto" md="auto" lg="auto" xl="auto" class="pb-0 pa-2 pl-2 d-flex justify-end">
          <v-card height="48" class="a-box d-flex align-center" :width="currentWidth">
          <div class="justify-space-around row ma-0 align-center">
            <v-tabs class="horizontal-tab underline tabsegment" tag="button"  style="position:relative;z-index: 7;" v-if="settingTabOpen">
              <v-tab @click="settingTabChange('rowCount')" class=""><img class="" src="@/assets/images/optionchain/strike.svg" /></v-tab>
              <v-tab @click="settingTabChange('fontresizer')" class=""><img class="" src="@/assets/images/optionchain/fonts.svg" /></v-tab>
              <v-tab @click="settingTabChange('colHideShow')" class=""><img class="" src="@/assets/images/optionchain/strikemenu.svg" /></v-tab>
              <v-tab @click="settingTabChange('downloadexcel')" class=""><img class="" src="@/assets/images/optionchain/file.svg" /></v-tab>
              <v-tab @click="settingTabChange('resetsetting')" class=""><img class="" src="@/assets/images/optionchain/settingreset.svg" /></v-tab>
            </v-tabs>
            <v-btn color="transparent" class="mx-2" elevation="0" min-width="25px" @click="settingTabOpen = !settingTabOpen;settingOpenClose(settingTabOpen);">
                <img class="" src="@/assets/images/optionchain/close.svg" v-if="settingTabOpen" />
                <img class="" src="@/assets/images/optionchain/settings.svg" v-if="!settingTabOpen"/>
            </v-btn>
          </div>
          <div style="display: block;clear: both;position: absolute;top: 54px;z-index: 7;width: 100%;">
            <div class="mt-1 w-100" v-if="fontresizer">
              <v-card class="pa-2 d-flex justify-space-around font-size" :class="tableFontSize">
                <label class="caption">Fontsize</label>
                <span><v-btn class="f--small ml-2" x-small rounded  @click="fontsizeChange('small')" outlined>SMALL</v-btn></span>
                <span><v-btn class="f--medium ml-2" x-small rounded  @click="fontsizeChange('medium')" outlined>MEDIUM</v-btn></span>
                <span><v-btn class="f--large ml-2" x-small rounded  @click="fontsizeChange('large')" outlined>LARGE</v-btn></span>
              </v-card>
            </div>
               <div class="mt-1 w-100" v-if="downloadexcel">
              <v-card class="pa-2 d-flex justify-space-around" height="35">
               <label class="mt-1" >Download Excel</label>
               <v-progress-linear class="progressindiator"  color="light-green darken-4" height="10" value="20" striped></v-progress-linear>
               <span class="mt-1">23%</span>   
               </v-card>         
            </div>
              <div class="mt-1 w-100" v-if="resetsetting">
              <v-card class="pa-2 d-flex justify-center" height="35">
               <button class="mt-0 align-center w-100"  @click="resetToDefault" >Reset to Default</button>    
               </v-card>         
            </div>
            <div class="mt-2" v-if="colHideShow">
            <v-card class="pa-1" height="40" width="230">
            <div class="text-center mt-2 mb-2" color="primary">Add/Remove Column</div>
            </v-card>
            <v-card class="px-4 overflowscroll" height="320" width="230" fill-height style="position: relative;top: -5px;">
            <div class="hide-show-col-list" v-for="(category,index) in optionchainSelection" :key="optionchainSelection[index].value">
              <v-checkbox  class="f-right pa-1 caption" :ripple = false hide-details :label="category.text" v-model="category.selected"></v-checkbox>
            </div> 
            </v-card>
            </div> 
                  <div>
                    <v-card class="mt-2"  height="240px" width="100"  v-if="rowCount">
                    <span class="mt-2">
                    <div class="text-center"><br>
                        <label class="caption">No of strikes</label></div>
                    <v-slider :max="3" step="1"  tick-size="4" :tick-labels="labels" @change="itemPerPagefun"  v-model="strike1"  class="mx-4 caption" ticks vertical
                        
                     ></v-slider></span>
                       <div class="text-center mt-1"><v-chip small>ATM</v-chip></div>
                     <!-- <v-slider v-model="strike2" :min="5" :max="15" step="5" :tick-labels="labels2" class="mx-4 caption" ticks vertical></v-slider> -->
                      <v-slider v-model="strike2" :max="3" step="1"  tick-size="4" @change="itemPerPagefun"  :tick-labels="labels2" class="mx-4 caption reverse" ticks vertical></v-slider> -
        
                    </v-card>
                    </div>  
          </div>
          </v-card>
        </v-col>
      </v-layout>
    </v-container>
  </v-flex>
  <v-flex xs12 class="pa-2 pt-0" @mouseover='setStrikePos'>
      <v-container fluid class="pa-0">
              <!-- :headers="optionchainTableHeader" :headers="optionchainTableHeader" v-for="h in optionchainTableHeader" -->
        <!-- <v-layout row wrap align-start justify-start > -->
          <div class="flex-table-container" >
            <div class="optionchain-table-container" id="ref-parent-id"  >
<!--             
              <div :data-font-size="tableFontSize"  :style="{'height':datatableHeight+'px'}" class="table-wrapper">
                  <table class="optionchain-table a-box w-100"  >
                    <thead style="position:sticky;top:0;">
                      <tr>
                        <th colspan="4" class="text-center bg-lightgreen" id="calls-th">CALLS</th>
                        <th class="bg-lightgrey">&nbsp;</th>
                        <th colspan="4" class="text-center bg-lightred" id="puts-th">PUTS</th>
                      </tr>
                      <tr class="f-light bg-lightgrey">
                        <th v-if="optionchainSelection.calloi.selected" class="text-right calls-clm-sub-hd">OI</th>
                        <th v-if="optionchainSelection.callltp.selected" class="text-right calls-clm-sub-hd">LTP</th>
                        <th v-if="optionchainSelection.putvolume.selected" class="text-right calls-clm-sub-hd">Volume</th>
                        <th v-if="optionchainSelection.calliv.selected" class="text-right calls-clm-sub-hd">IV</th>
                        <th v-if="optionchainSelection.strike.selected" class="text-center str-clm pa-0">Strike</th>
                        <th v-if="optionchainSelection.putiv.selected" class="text-right puts-clm-sub-hd">IV</th>
                        <th v-if="optionchainSelection.putvolume.selected" class="text-right puts-clm-sub-hd">Volume</th>
                        <th v-if="optionchainSelection.putoi.selected" class="text-right puts-clm-sub-hd">LTP</th>
                        <th v-if="optionchainSelection.putltp.selected" class="text-right puts-clm-sub-hd">OI</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr class="text-right" v-for="(item) in oct" :key="item.ref" :class="{ 'c-active': item.current == true}" :ref="item.ref"  :data-ref="item.ref"  
                          @mouseover='getPos(item.ref,item, oct.map(function(x) {return x.ref; }).indexOf(item.ref),$event)'>
                              <td v-if="optionchainSelection.calloi.selected" :class="{ 'bg-lightyellow': item.hightlight=='call' }"
                                @mouseover='setType("call")'>
                                {{ item.ceTodayOpenInterest  }}<br>
                                <span class="s2ndline" :class=" item.ceChangeInOI >= 0 ? 'negativevalue' : 'positivevalue'">({{ item.ceChangeInOI }}%)</span>
                              </td>

                              <td v-if="optionchainSelection.callltp.selected" :class="{ 'bg-lightyellow': item.hightlight=='call' }" 
                                @mouseover='setType("call")'
                              >{{ item.ceTodayClose }}<br>
                              <span class="s2ndline" :class=" item.ceChangeInPrice >= 0 ? 'negativevalue' : 'positivevalue'">{{ parseFloat(item.ceChangeInPrice).toFixed(2) }} ({{ parseFloat(item.ceChangeInPrice).toFixed(2) }}%)</span>
                              </td>

                            <td v-if="optionchainSelection.callvolume.selected" :class="{ 'bg-lightyellow': item.hightlight=='call' }" 
                              @mouseover='setType("call")'
                            >{{ item.ceTodayTradedQty }}</td>

                            <td v-if="optionchainSelection.calliv.selected" :class="{ 'bg-lightyellow': item.hightlight=='call' }" 
                              @mouseover='setType("call")'
                            >{{ item.ceIV }}</td>
                            


                              <td v-if="optionchainSelection.strike.selected" class="bg-lightgrey c-blue str-clm text-center pa-0" :class="{ 'p-relative': item.current == true}"
                              @mouseover='closeBuySellBtn' >
                                <span v-if="item.current == true" :data-strike="{ 'currentStrikePrice': item.current == true}" 
                                  class='text-center strike-highlight'>
                                    {{currentStrikePrice}} (+0.01%)
                                </span>
                                {{ item.ceInstrument_Identifier.split("_")[4] }}
                              </td>
                            
                            <td v-if="optionchainSelection.putiv.selected" :class="{ 'bg-lightyellow': item.hightlight=='put' }" 
                              @mouseover='setType("put")'
                            >{{ item.peIV }}</td>

                            <td v-if="optionchainSelection.putvolume.selected" :class="{ 'bg-lightyellow': item.hightlight=='put' }" 
                              @mouseover='setType("put")'
                            >{{ item.peTodayTradedQty }}</td>

                              <td v-if="optionchainSelection.putltp.selected" :class="{ 'bg-lightyellow': item.hightlight=='put' }"
                                @mouseover='setType("put")'
                              >{{ item.peTodayClose }}<br>
                              <span class="s2ndline" :class=" item.peChangeInPrice >= 0 ? 'negativevalue' : 'positivevalue'">{{ parseFloat(item.peChangeInPrice).toFixed(2)  }} ({{ parseFloat(item.peChangeInPrice).toFixed(2) }}%)</span>  
                              </td>

                              <td v-if="optionchainSelection.putoi.selected" :class="{ 'bg-lightyellow': item.hightlight=='put' }" 
                                @mouseover='setType("put")'
                              >{{ item.peTodayOpenInterest }}<br><span class="s2ndline" :class=" item.peChangeInOI >= 0 ? 'negativevalue' : 'positivevalue'" >{{ item.peChangeInOI }}%</span></td>


                        </tr> 
                        <tr> <td colspan="9" class="text-center">tetst</td></tr>
                    </tbody>
                  </table>  
              </div> -->
            
            <v-data-table dense  :data-font-size="tableFontSize" hide-default-header :height="datatableHeight"  fixed-header
              :items="oct" :items-per-page="itemPerPage"  
              class="optionchain-table a-box" > 
                <template v-slot:header="{ props: { optionchainTableHeader } }">
                  <thead id="thead">
                    
                    <tr>
                      <th colspan="4" class="text-center bg-lightgreen" id="calls-th">CALLS</th>
                      <th class="bg-lightgrey">&nbsp;</th>
                      <th colspan="4" class="text-center bg-lightred" id="puts-th">PUTS</th>
                    </tr>
                    <tr class="f-light bg-lightgrey">
                      <th v-if="optionchainSelection.calloi.selected" class="text-right calls-clm-sub-hd">OI</th>
                      <th v-if="optionchainSelection.callltp.selected" class="text-right calls-clm-sub-hd">LTP</th>
                      <th v-if="optionchainSelection.putvolume.selected" class="text-right calls-clm-sub-hd">Volume</th>
                      <th v-if="optionchainSelection.calliv.selected" class="text-right calls-clm-sub-hd">IV</th>
                      <th v-if="optionchainSelection.strike.selected" class="text-center str-clm pa-0">Strike</th>
                      <th v-if="optionchainSelection.putiv.selected" class="text-right puts-clm-sub-hd">IV</th>
                      <th v-if="optionchainSelection.putvolume.selected" class="text-right puts-clm-sub-hd">Volume</th>
                      <th v-if="optionchainSelection.putoi.selected" class="text-right puts-clm-sub-hd">LTP</th>
                      <th v-if="optionchainSelection.putltp.selected" class="text-right puts-clm-sub-hd">OI</th>
                    </tr>
                  </thead>
                </template>
        <!-- @mouseenter="item.isHovering = true"
        @mouseleave="item.isHovering = false" :data-hover="item.isHovering"
        @mouseenter="itemUpdate(item,true)"
        @mouseout="itemUpdate(item,false)"-->
                <template v-slot:item="{ item }" @scroll='handleScroll'>
                  <tr class="text-right" :class="{ 'c-active': item.current == true}" :data-hover="item.isHovering" :ref="item.ref"   
                  @mouseleave="mouseLeaveTr(item.ref,item, oct.map(function(x) {return x.ref; }).indexOf(item.ref),$event)"
                   @mouseenter="itemUpdate(item,true)"
                    @mouseover='getPos(item.ref,item, oct.map(function(x) {return x.ref; }).indexOf(item.ref),$event)'>
                        <td v-if="optionchainSelection.calloi.selected" :class="{ 'bg-lightyellow': item.hightlight=='call' }"
                          @mouseover='setType("call")'>
                          {{ item.ceTodayOpenInterest  }}<br>
                          <span class="s2ndline" :class=" item.ceChangeInOI >= 0 ? 'negativevalue' : 'positivevalue'">({{ item.ceChangeInOI }}%)</span>
                        </td>

                        <td v-if="optionchainSelection.callltp.selected" :class="{ 'bg-lightyellow': item.hightlight=='call' }" 
                          @mouseover='setType("call")'
                        >{{ item.ceTodayClose }}<br>
                        <span class="s2ndline" :class=" item.ceChangeInPrice >= 0 ? 'negativevalue' : 'positivevalue'">{{ parseFloat(item.ceChangeInPrice).toFixed(2) }} ({{ parseFloat(item.ceChangeInPrice).toFixed(2) }}%)</span>
                        </td>

                      <td v-if="optionchainSelection.callvolume.selected" :class="{ 'bg-lightyellow': item.hightlight=='call' }" 
                        @mouseover='setType("call")'
                      >{{ item.ceTodayTradedQty }}</td>

                      <td v-if="optionchainSelection.calliv.selected" :class="{ 'bg-lightyellow': item.hightlight=='call' }" 
                        @mouseover='setType("call")'
                      >{{ item.ceIV }}</td>
                    
                        <td v-if="optionchainSelection.strike.selected" class="bg-lightgrey c-blue str-clm p-relative text-center"
                         @mouseover='closeBuySellBtn' >
                          <span v-if="item.current == true" :data-strike="{ 'currentStrikePrice': item.current == true}"  id="strike-highlight"
                            class='text-center strike-highlight'>
                              {{currentStrikePrice}} (+0.01%)
                          </span>
                          {{ item.ceInstrument_Identifier.split("_")[4] }}
                        </td>
                      
                      <td v-if="optionchainSelection.putiv.selected" :class="{ 'bg-lightyellow': item.hightlight=='put' }" 
                        @mouseover='setType("put")'
                      >{{ item.peIV }}</td>

                      <td v-if="optionchainSelection.putvolume.selected" :class="{ 'bg-lightyellow': item.hightlight=='put' }" 
                        @mouseover='setType("put")'
                      >{{ item.peTodayTradedQty }}</td>

                        <td v-if="optionchainSelection.putltp.selected" :class="{ 'bg-lightyellow': item.hightlight=='put' }"
                          @mouseover='setType("put")'
                        >{{ item.peTodayClose }}<br>
                        <span class="s2ndline" :class=" item.peChangeInPrice >= 0 ? 'negativevalue' : 'positivevalue'">{{ parseFloat(item.peChangeInPrice).toFixed(2)  }} ({{ parseFloat(item.peChangeInPrice).toFixed(2) }}%)</span>  
                        </td>

                        <td v-if="optionchainSelection.putoi.selected" :class="{ 'bg-lightyellow': item.hightlight=='put' }" 
                          @mouseover='setType("put")'
                        >{{ item.peTodayOpenInterest }}<br><span class="s2ndline" :class=" item.peChangeInOI >= 0 ? 'negativevalue' : 'positivevalue'" >{{ item.peChangeInOI }}%</span></td>


                  </tr> 
                </template>

            </v-data-table> 
            <transition name="fade">
            <div class="strike-price" 
            style="position:absolute;z-index:8;top:0;left:0;width:100%;border-top: 2px dashed rgb(0, 184, 148);height:1px;"
            :style="{top:tabeStrikePosition+'px'}" v-if="runningStrike">
                  <span class='text-center strike-highlight'>{{currentStrikePrice}} (+0.01%)</span>
            </div>
            </transition>
            <!-- <div class="hidden-layer" ></div> -->
            <div class="hidden-layer" @mouseover='closeBuySellBtn' v-if="buySellArea.show"></div>
            <div v-if="buySellArea.show" class="buy-sell-area" 
              :style="{top:buySellArea.top,left: buySellArea.left,right: buySellArea.right,width: buySellArea.buySellAreaWidth+'px'}"
              @mouseover='getPosP()'
            >
              <!-- @mouseleave='getPosPL()'  -->
              <!-- <div :class="(buySellArea.left != 'auto')?'float-right':'float-left' "> -->
                <span class="btn buy" @click='gotoBasket("buy")'>B</span>
                <span class="btn sell" @click='gotoBasket("sell")'>S</span>
              <!-- </div> -->
            </div>
            </div>
          </div>
        <!-- </v-layout> -->
      </v-container>
  </v-flex>
</div>




</template>

<script>
import { mapState, mapGetters,mapActions,mapMutations } from "vuex"
import commonFunctions from "@/mixins/commonFunctions"
import { gql } from "graphql-tag";
const INSTRUMENTS_HISTORY_QUERY = gql`
  query instrumentsHistory($instruments: [String]) {
    instrumentsHistory(instruments: $instruments) {
      instrument
      data {
        INSTRUMENTIDENTIFIER
        OPEN
        HIGH
        LOW
        CLOSE
        OPENINTEREST
        TRADEDQTY
        EXCHANGE
        LASTTRADETIME
      }
    }
  }
`;
const INSTRUMENTS_SUBSCRIPTION = gql`
  subscription subscribedInstruments($instruments: [String]) {
    subscribedInstruments(instruments: $instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`;
const INSTRUMENTS_QUERY = gql`
  query subscribedInstruments($instruments: [String]) {
    subscribedInstruments(instruments: $instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`;
let subscribedInstruments = {
    INSTRUMENTIDENTIFIER: "",
    OPEN: "0.0",
    HIGH: "0.0",
    LOW: "0.0",
    CLOSE: "0.0",
};
let instrumentsHistory = {};

export default {
  mixins: [commonFunctions],
  data() {
    return {runningStrike:false,
      buySellArea:{
        left:"auto",top:"auto",right:"auto",show:false,currentObj:{},hoverState:"call",buySellAreaWidth:66,showBasket:true,
      },datatableHeight:(window.innerHeight - 136),dialog: false,tabeStrikePosition:0,

      basketList:[
          { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
          { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
          { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' }
      ],
      searchitems:["Bank Nifty ","Nifty PSU Bank ","Nifty Private Bank ","HDFC Bank ","ICICI Bank ","State Bank of India ","Kotak Mahindra Bank ","Axis Bank ","IndusInd Bank ","IDBI Bank"],
      closeOuterLayer:false,currentStrikePrice:'123',
      instrument: "BANKNIFTY",
      expirydate: "18NOV21",
      expirydatelist:"",
      instrumentname: "Banknifty",
      strikeprice: 0,
      expiryDateList: false,
      selectedExpiryDate: "Select",
      fontresizer: false,resetsetting: false,downloadexcel: false,colHideShow: false,rowCount: false,
      settingTabOpen: false,
      currentWidth:'',
      expand:false,
      optionchain: [
        {percentage: '-0.47',tysm: "BANKNIFTY 57089.89",ivpercentage:"-1.9"}
      ],
      optionchainSelection: {
          calloi:{ text: 'Call OI',value:'calloi', selected : true  },
          callcoi:{ text: 'Call COI',value:'callcoi',selected : true  },
          calliv:{ text: 'Call IV',value:'calliv', selected : true },
          callvolume:{ text: 'Call Volume',value:'callvolume',  selected : true  },
          callltpper:{ text: 'Call LTP %',value:'callltpper', selected : true  },
          callltpchng:{ text: 'Call LTP Chg',value:'callltpchng', selected : true },
          callltp:{ text: 'Call LTP',value:'callltp',  selected : true },
          strike:{ text: 'Strike',value:'strike',selected : true },
          putoi:{ text: 'Put OI',value:'putoi', selected : true  },
          putcoi:{ text: 'Put COI',value:'putcoi',selected : true  },
          putiv:{ text: 'Put IV',value:'putiv', selected : true },
          putvolume:{ text: 'Put Volume',value:'putvolume',  selected : true  },
          putltpper:{ text: 'Put LTP %',value:'putltpper', selected : true  },
          putltpchng:{ text: 'Put LTP Chg',value:'putltpchng', selected : true },
          putltp:{ text: 'Put LTP',value:'putltp',  selected : true },
        },
        tableFontSize: "medium",
        // optionchainTableHeader:[
        //   { text: 'OI',value:'calloi'},
        //   { text: 'COI',value:'callcoi'},
        //   { text: 'IV',value:'calliv'},
        //   { text: 'Volume',value:'callvolume'},
        //   { text: 'LTP %',value:'callltpper'},
        //   { text: 'LTP Chg',value:'callltpchng'},
        //   { text: 'LTP',value:'callltp'},
        //   { text: 'Strike',value:'strike'},
        //   { text: 'OI',value:'putoi'},
        //   { text: 'COI',value:'putcoi'},
        //   { text: 'IV',value:'putiv'},
        //   { text: 'Volume',value:'putvolume'},
        //   { text: 'LTP %',value:'putltpper'},
        //   { text: 'LTP Chg',value:'putltpchng'},
        //   { text: 'LTP',value:'putltp'},
        // ],
        optionchainTableHeader:[
          { text: 'OI',value:'calloi'},
          { text: 'LTP',value:'callltp'},
          { text: 'Strike',value:'strike'},
          { text: 'OI',value:'putoi'},
          { text: 'LTP',value:'putltp'},
        ],
        oct:[],
        // optionchainTableData: [
        //   { hightlight:"call", calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"call", calloi: 232, callcoi: -4323, calliv: 323, callvolume: 12, callltpper: 65, callltpchng: -32, callltp: 222, strike: 675, putoi: 876, putcoi: 564, putiv: 6556, putvolume: 345, putltpper: 43, putltpchng: 544, putltp: 545, }, 
        //   { hightlight:"call", calloi: 543, callcoi: 5544, calliv: 3456, callvolume: 23, callltpper: 89, callltpchng: -578, callltp: 2124, strike: 7896, putoi: 987, putcoi: 474, putiv: 343, putvolume: -343, putltpper: 34, putltpchng: -57, putltp: 346, }, 
        //   { hightlight:"call", calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"call", calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"call", calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, },
        //   { hightlight:"call", calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"call", calloi: 232, callcoi: -4323, calliv: 323, callvolume: 12, callltpper: 65, callltpchng: -32, callltp: 222, strike: 675, putoi: 876, putcoi: 564, putiv: 6556, putvolume: 345, putltpper: 43, putltpchng: 544, putltp: 545, }, 
        //   { current:true,hightlight:"call", calloi: 543, callcoi: 5544, calliv: 3456, callvolume: 23, callltpper: 89, callltpchng: -578, callltp: 2124, strike: 7896, putoi: 987, putcoi: 474, putiv: 343, putvolume: -343, putltpper: 34, putltpchng: -57, putltp: 346, }, 
        //   { hightlight:"put",  calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"put",  calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"put",  calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, },
        //   { hightlight:"put",  calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"put",  calloi: 232, callcoi: -4323, calliv: 323, callvolume: 12, callltpper: 65, callltpchng: -32, callltp: 222, strike: 675, putoi: 876, putcoi: 564, putiv: 6556, putvolume: 345, putltpper: 43, putltpchng: 544, putltp: 545, }, 
        //   { hightlight:"put",  calloi: 543, callcoi: 5544, calliv: 3456, callvolume: 23, callltpper: 89, callltpchng: -578, callltp: 2124, strike: 7896, putoi: 987, putcoi: 474, putiv: 343, putvolume: -343, putltpper: 34, putltpchng: -57, putltp: 346, }, 
        //   { hightlight:"put",  calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"put",  calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"put",  calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, },
        //   { hightlight:"put",  calloi: 1250, callcoi: -3250, calliv: 7250, callvolume: 20, callltpper: 29, callltpchng: -539, callltp: 333, strike: 3000, putoi: 1250, putcoi: -3250, putiv: 7250, putvolume: 20, putltpper: 29, putltpchng: -539, putltp: 333, }, 
        //   { hightlight:"put",  calloi: 232, callcoi: -4323, calliv: 323, callvolume: 12, callltpper: 65, callltpchng: -32, callltp: 222, strike: 675, putoi: 876, putcoi: 564, putiv: 6556, putvolume: 345, putltpper: 43, putltpchng: 544, putltp: 545, }, 
        // ],
        itemPerPage:0,
      strike1: 0,strike2:3,
       labels: [],
       labels2: [],
    };
  },
  methods: {
    handleScroll(e){
      console.log(e)
    },
    gotoBasket(bs){
      this.basketGval.showBasket = true;
      var val = this.buySellArea.currentObj.item;
      var currentdate = new Date(), 
          dynamicId = currentdate.getDate()+""+ (currentdate.getMonth()+1)+currentdate.getFullYear()+"" +
           currentdate.getHours() +""+  currentdate.getMinutes() +""+ currentdate.getSeconds();
           console.log(this.buySellArea.currentObj.type)
      var type = (this.buySellArea.currentObj.type=="call")?"ce":"pe"
      var obj = {
        stock:val[type+"Instrument_Identifier"].split("_")[0]+" "+val[type+"Instrument_Identifier"].split("_")[1]
        +" "+val[type+"Instrument_Identifier"].split("_")[2]+" "+val[type+"Instrument_Identifier"].split("_")[3],
        buySell:bs,
        callPut:this.buySellArea.currentObj.type,
        value:val[type+"Instrument_Identifier"].split("_")[4],
        limit:Math.floor(Math.random() * 50),
        currentIndex:dynamicId,
        marketType:['MKT','LMT'][Math.floor(Math.random() * 2)]
      }
      this.ADD_ORDER(obj);
      // this.updateBasketGval({stock:"NIFTY NOV 18000 CE",value:110.70,limit:1,marketType:"MKT"})
      
      // console.log(bs+"::"+this.buySellArea.currentObj.type+"::"+this.buySellArea.currentObj.index+"::"+this.buySellArea.currentObj.ref)
    },
    closeBuySellBtn(e){
      // console.log(e.target)
      this.buySellArea.show = false;
      // this.setStrikePos();
    },
    mouseLeaveTr(ref,item,index,e){
      this.oct.splice(index, 1, { ...this.oct[index], ...{ isHovering: false } })
      this.setStrikePos();
    },
    setStrikePos(){
      // console.log("setStrikePos");
      setTimeout(() => {
        var refE = document.getElementById('ref-parent-id');
        var shE = document.getElementById('strike-highlight');
        if(shE != null && refE!= null){
          var parentPos = refE.getBoundingClientRect().y
          var strikePos = shE.getBoundingClientRect().y;
          var tableBodyPos = parentPos + document.getElementById('thead').offsetHeight;
          var bottomPos = parentPos + this.datatableHeight;
          // console.log("strikePos:"+strikePos+" - parentPos:"+parentPos+"="+(strikePos-parentPos));
          //this.tabeStrikePosition = (strikePos - parentPos + 12);
          // if(tableBodyPos,strikePos)
          this.runningStrike = true;
          var strikeTopPos = 0;
          if(tableBodyPos<=strikePos){
            if(bottomPos>strikePos){
              this.runningStrike = false;
            }else{
              strikeTopPos = this.datatableHeight;
            }
          }else{
            strikeTopPos = 67;
          }
          this.tabeStrikePosition = strikeTopPos;
          // console.log(tableBodyPos,strikePos,this.tabeStrikePosition)
        }
      }, 500);
    },
    setType(type){
      this.hoverState = type;
      this.buySellArea.show = true;
    },
    getPosL(ref,item,index) {
      // console.log(ref,item,index)
      // this.oct.map(function(x) { x.isHovering=false ; })
      this.buySellArea.show = false;
    },
    getPosP() {
      var obj = this.buySellArea.currentObj;
      // console.log(obj)
      this.getPos(obj.ref,obj.item,obj.index,obj.type);
      this.itemUpdate(obj.item,true)
    },
    getPosPL(){
      var obj = this.buySellArea.currentObj;
      // console.log(obj)
      this.getPosL(obj.ref,obj.item,obj.index,obj.type);
      this.itemUpdate(obj.item,false)
    },
    itemUpdate(item,val) {
      this.oct.map(function(x) { x.isHovering=false ; })
      this.oct.splice(item.currentIndex, 1, { ...this.oct[item.currentIndex], ...{ isHovering: val } })
    },
    getPos(ref,item,index,e) {
      
      // if(e)
      // console.log(e)
      // console.log(this.$refs[ref].getBoundingClientRect())
      // console.log(item)
      // item.isHovering = true;
      // console.log(item.isHovering)
      this.buySellArea.currentObj = {ref:ref,item:item,index:index,type:this.hoverState}
      // console.log(ref,item,index)
      var parentPos = document.getElementById('ref-parent-id').getBoundingClientRect();
      const tr = this.$refs[ref].getBoundingClientRect();
      // console.log(tr.y , parentPos.y)
      this.buySellArea.left =  this.buySellArea.right = "auto";
      this.buySellArea.top = (tr.y - parentPos.y)+12+"px";
      if(this.hoverState=="call"){
        // this.buySellArea.left = 0;
        this.buySellArea.left = document.querySelector('#calls-th').offsetWidth-80+"px";
      }else{
        // this.buySellArea.right = 0;
        this.buySellArea.right = document.querySelector('#puts-th').offsetWidth-80+"px";
      }
      // console.log(this.buySellArea)
    },
    resetSettingsOption(){this.rowCount = this.colHideShow = this.resetsetting = this.fontresizer = this.downloadexcel = false;},
    settingOpenClose(status){
      if(status){this.rowCount =true;this.currentWidth = "auto";}else{this.currentWidth = "70px";this.resetSettingsOption()}
    },
    loadData(val){
      // console.log(this.expirydate,this.currentStrikePrice,this.instrument)
      this.$store.dispatch('optionChain/getOptionChain', { expiry :this.expirydate, instrument : this.instrument});
      
      setTimeout(() => {
        this.optionchainTableData = this.getOptionsList;
        this.itemPerPagefun();
      }, 500);
      //  console.log(JSON.stringify(this.optionchainTableData));
    },
    itemPerPagefun(){
      // console.log("-----------------------------------")
      // console.log(this.optionchainTableData)
      var step = 5,option=4;
      for(var j=1;j<=option;j++){
        this.labels.push(j*step) // = [5,10,15,20]
        this.labels2.unshift(j*step) // = [20,15,10,5]
      }
      var s1= this.strike1,s2= this.strike2,s2val=s2+(4*step) - ((s2*step)+s2),s1val=(s1+1)*step;
      // console.log(s1val , s2val)
      this.itemPerPage =  (s1val + s2val+1);
      // var optionchainTableDataAfter = this.getUOptionsList.slice(0);
      // console.log(this.optionchainTableData)
      var optionchainTableDataAfter = this.optionchainTableData.slice(0);
      //console.log(optionchainTableDataAfter)
        function getCurrentPos(){
          for(var i=0;i<optionchainTableDataAfter.length;i++){
              if(optionchainTableDataAfter[i].current == true)
                  return i; 
          }
        }
        var currentPos = getCurrentPos();
        // console.log(this.optionchainTableData,optionchainTableDataAfter)
        if(currentPos>s1val){var dif = currentPos-s1val;optionchainTableDataAfter.splice(0, dif)}
        currentPos = getCurrentPos()+1;
        if(optionchainTableDataAfter.length>(currentPos+s2val)){
          var dif = optionchainTableDataAfter.length-currentPos-s2val;
          while (dif--)
            optionchainTableDataAfter.pop();
        }
        optionchainTableDataAfter.map(function(x,i) { x.currentIndex=i; })
        this.oct = optionchainTableDataAfter;
      // console.log(this.oct)
        this.setStrikePos;

    },
    fontsizeChange(current){this.tableFontSize = current},
    settingTabChange(selectedTab){this.resetSettingsOption();this[selectedTab] = true;},
    resetToDefault() {
      for (const item of this.optionchainTableHeader) {this.optionchainSelection[item['value']].selected = true;}
      this.tableFontSize = "medium";
      this.strike1= 5;this.strike2= 5;
    },
     async initialFunctions() {
      await this.$store.dispatch('symbols/getSymbolsList');
      await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
      await this.$store.dispatch('symbols/getCurrentMarketPrice', this.instrument);
      // this.$store.commit('symbols/setCurrentStrikePrice');
      // this.$store.commit('symbols/setStrickPrice',await { expiry:this.expirydate , instrument:this.instrument})
      await this.$store.dispatch('optionChain/getOptionChain', { expiry :this.expirydate, instrument : this.instrument});
      //this.strikeprice = await this.getCurrentPrice(this.currentStrikePrice)
      this.optionchainTableData = this.getOptionsList; 
      this.currentStrikePrice = this.$store.state.updateCurrentStrikePrice;
      this.itemPerPagefun();
    },
    ...mapMutations(['ADD_ORDER']),
    ...mapActions(['removeOrder']),
  },

  apollo: {
    instrumentsHistory: {
      query: INSTRUMENTS_HISTORY_QUERY,
      variables() {
        return {
          instruments: [
            // `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace('21','2021'): ''}_PE_${this.strikeprice}`,
            // `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace('21','2021'): ''}_CE_${this.strikeprice}`,
            `OPTIDX_${this.instrument}_${this.expirydate.substring(0,5)+ "20" + this.expirydate.substring(5)}_PE_${this.strikeprice}`,
            `OPTIDX_${this.instrument}_${this.expirydate.substring(0,5)+ "20" + this.expirydate.substring(5)}_CE_${this.strikeprice}`,

          ],
        };
      },
      update: (data) => {
        instrumentsHistory = data.instrumentsHistory;
        return instrumentsHistory;
      },
      result() {
      },
    },
  },
    computed: {
      ...mapState(['basketGval']),
      ...mapState('symbols',['symbolsList','expiryList','strickPriceList','optionList']),
      ...mapGetters('optionChain', ['getOptionsList','getUOptionsList']),
      // ...mapActions(['updateBasketGval'])
    },
   watch: {
    // instrument: function(val) {
    //   this.$store.dispatch('optionChain/getOptionChain', { expiry :this.expirydate, instrument : this.instrument});
    // },
    // expirydate: function(val) {
    //   this.$store.dispatch('optionChain/getOptionChain', { expiry :this.expirydate, instrument : this.instrument});
    // },
    //strike1: function(val) {this.itemPerPagefun();},
    //strike2: function(val) {this.itemPerPagefun();}
   },
  mounted() {
    this.initialFunctions();
    document.addEventListener('wheel', this.setStrikePos)
  },
};
</script>